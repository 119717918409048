<div
  class="flex items-center gap-2 mr-2 min-w-[193px] rounded-lg border-planning-red border px-2 cursor-pointer"
  [matMenuTriggerFor]="detailIntersection"
  [style.min-height.px]="height"
  [style.max-height.px]="height"
>
  <mat-icon class="icon-size-4 text-timeline-gray" svgIcon="heroicons_outline:information-circle"></mat-icon>
  <mat-menu #detailIntersection="matMenu" yPosition="above" xPosition="before" class="max-w-none w-full">
    <com-intersection-detail [events]="events"></com-intersection-detail>
  </mat-menu>

  <span class="text-foreground text-[14px] font-medium">Занят в:</span>
  <div class="flex justify-center items-center">
    {{ busyInterval }}
  </div>
</div>
