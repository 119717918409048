import { NgModule } from '@angular/core';
import { UserCheckComponent } from '@common/shared/components/form-groups/user-check/user-check.component';
import { BusyTimelineModule } from '@common/dialogs/planning-dialog/modules/planning-intersection/modules/busy-timeline/busy-timeline.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [UserCheckComponent],
  exports: [UserCheckComponent],
  imports: [BusyTimelineModule, CommonModule, BusyTimelineModule]
})
export class UserCheckModule {}
