import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, Self } from '@angular/core';
import { EmployeeService, UnsubscribeService } from '@common/services';
import { BehaviorSubject, debounceTime, Observable, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { IEmployeeOption, IEmployeeShort, IOption } from '@common/types';
import {
  employeeOptionMapper,
  optionMapper
} from '@common/modules/committees/committees-form/utils/adaptors';
import {
  SHORT_CUT,
  ShortCut,
  ShortCutSelectValue,
  ShortCutValue
} from '@common/shared/components/form-groups/no-system-members/no-system-members.types';
import { IMemberFormValue } from '@common/shared/components/form-groups/members/members.types';

@Component({
  selector: 'com-no-system-members',
  templateUrl: 'no-system-members.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UnsubscribeService]
})
export class NoSystemMembersComponent {
  public userOptions$ = new BehaviorSubject<IEmployeeOption[]>([]);
  public positionOptions$ = new BehaviorSubject<IOption[]>([]);
  public loading$ = new BehaviorSubject<boolean>(true);
  public search$ = new Subject<string>();
  public selectedTab = ShortCut.Users;
  public SHORT_CUT = SHORT_CUT;
  public JSON = JSON;
  public value: ShortCutSelectValue[] = [];

  @Input() members: IMemberFormValue[];
  @Output() selected = new EventEmitter<ShortCutValue[]>();

  @Input()
  set nonSystemMembers(value: ShortCutValue[]) {
    this.value = value.map((o) => {
      return {
        id: o.value,
        heading: o.name,
        shortcut: o.shortcut
      };
    });
  }

  constructor(
    public employeeService: EmployeeService,
    @Self() private unsubscribeService: UnsubscribeService
  ) {
    this.searchSub();
  }

  public onSelect(noSystemOptions: ShortCutSelectValue[]) {
    this.selected.emit(
      noSystemOptions.map((o) => ({
        name: o.heading,
        value: o.id,
        shortcut: o.shortcut
      }))
    );
  }

  public onTabChange(tab: ShortCut) {
    this.selectedTab = tab;
    this.search$.next(' ');
  }

  private searchSub(): void {
    this.search$
      .pipe(
        debounceTime(50),
        tap(() => this.loading$.next(true)),
        switchMap((query) => {
          if (this.selectedTab === 'positions') {
            return this.positionSearch(query);
          }
          return this.userSearch(query);
        }),
        tap(() => this.loading$.next(false)),
        takeUntil(this.unsubscribeService)
      )
      .subscribe();
  }

  private userSearch(query: string): Observable<IEmployeeShort[]> {
    return this.employeeService
      .retrieveEmployeeSearchForSelect(query, 0, 50)
      .pipe(
        tap((employees) =>
          this.userOptions$.next(this.filterNoCommitteeMembers(employees.map(employeeOptionMapper)))
        )
      );
  }

  private filterNoCommitteeMembers(users: IEmployeeOption[]): IEmployeeOption[] {
    const userIds = this.members.map((m) => m?.employeeOption?.id);
    return users.filter((u) => !userIds.includes(u.id));
  }

  private positionSearch(query: string): Observable<string[]> {
    return this.employeeService
      .getPositions(query, 50)
      .pipe(tap((positions) => this.positionOptions$.next(positions.map(optionMapper).filter(Boolean))));
  }
}
