import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EkpSelectComponent } from '@common/shared/components/ekp-select/ekp-select.component';

@NgModule({
  declarations: [EkpSelectComponent],
  exports: [EkpSelectComponent],
  imports: [CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EkpSelectModule {}
