import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EventInterval } from '@common/dialogs/planning-dialog/types';

@Component({
  selector: 'com-busy-inform-button',
  templateUrl: 'busy-inform-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BusyInformButtonComponent {
  @Input() events: EventInterval[] = [];
  @Input() forecastEventStart: string;
  @Input() forecastEventEnd: string;
  @Input() busyInterval: string;
  @Input() height = 44;
}
