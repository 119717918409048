import { Injectable } from '@angular/core';
import { WeekDayCell, WeekDayMap } from '@common/dialogs/planning-dialog/modules/planning-diagram/types';
import { HolidayService } from '@common/services';
import { CommitteeIntersection, DateInfo, DayType } from '@common/dialogs/planning-dialog/types';
import {
  addDays,
  addHours,
  eachDayOfInterval,
  endOfWeek,
  endOfYear,
  isSameDay,
  isSameYear,
  isToday,
  startOfWeek,
  startOfYear
} from 'date-fns';
import { CellType, WeekDay } from '@common/dialogs/planning-dialog/modules/planning-diagram/const';
import { setTimeZone } from '@common/dialogs/intersection-dialog/helpers/date.helpers';
import { startOfDayString } from '@common/dialogs/planning-dialog/utils';
import { RRuleModel } from '@common/types';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IntersectionDiagramService {
  public weekDayMap$ = new BehaviorSubject<WeekDayMap>(null);
  public availableYears$ = new BehaviorSubject<string[]>([]);
  public indexMap: Record<string, [weekDay: string, index: number]> = {};
  public selectedYear$ = new BehaviorSubject<string>('');

  private yearMap: Record<string, CommitteeIntersection[] | DateInfo[]> = {};

  constructor(private holidayService: HolidayService) {
    this.initSelectedYear();
  }

  public groupByYear<T extends CommitteeIntersection | DateInfo>(events: T[]): void {
    this.yearMap = events.reduce(
      (acc, event) => {
        let year: number;

        if ('timeStart' in event) {
          year = new Date(event.timeStart).getFullYear();
        } else if ('date' in event) {
          year = new Date(event.date).getFullYear();
        }

        if (!acc[year]) {
          acc[year] = [];
        }
        acc[year].push(event);
        return acc;
      },
      {} as Record<string, T[]>
    ) as Record<string, CommitteeIntersection[] | DateInfo[]>;

    this.initAvailableYears();
  }

  public setYear(year: string): void {
    this.selectedYear$.next(year);
  }

  private initAvailableYears(): void {
    this.availableYears$.next(Object.keys(this.yearMap));
  }

  public initSelectedYear(date?: string): void {
    this.selectedYear$.next(new Date(date).getFullYear().toString());
  }

  public update(rrule: RRuleModel, selectedDay?: string, transferDate?: string): void {
    const events = this.yearMap[this.selectedYear$.value] as CommitteeIntersection[];
    const { value } = this.weekDayMap$;
    const map = {} as WeekDayMap;
    for (const weekDay in value) {
      const cells: WeekDayCell[] = value[weekDay];

      map[weekDay] = cells.map((cell, index) => {
        this.addRecordInIndexMap(cell.date, weekDay, index);
        this.setDefaultCellValue(rrule, cell);

        events.map((e) => {
          if (isSameDay(cell.date, new Date(e.timeStart))) {
            cell.type = e.hasIntersection ? CellType.Intersection : CellType.Planning;
            cell.eventDate = e.timeStart;
            cell.id = e.id;
            cell.intersectionOrder = e.intersectionOrder;
            cell.showOrder = this.showOrder(cell.date, selectedDay, transferDate);
          }
        });
        return cell;
      });
    }

    events.map((e) => {
      if (e.transferedStart) {
        const [weekDay, i] = this.getCellByDate(e.transferedStart);
        map[weekDay][i] = {
          ...map[weekDay][i],
          id: e.id,
          eventDate: e.timeStart,
          type: CellType.Transfer,
          intersectionOrder: e.intersectionOrder,
          showOrder: this.showOrder(map[weekDay][i].date, selectedDay, transferDate)
        };
      }
    });
    this.weekDayMap$.next(map);
  }

  public updateByTransfer(): void {
    const events = this.yearMap[this.selectedYear$.value] as DateInfo[];
    const { value } = this.weekDayMap$;
    const map = {} as WeekDayMap;
    for (const weekDay in value) {
      const cells: WeekDayCell[] = value[weekDay];

      map[weekDay] = cells.map((cell) => {
        events.map((e) => {
          if (isSameDay(cell.date, setTimeZone(e.date))) {
            cell.type = this.getTransferCellType(e.type);
          }
        });
        return cell;
      });
    }
    this.weekDayMap$.next(map);
  }

  private getTransferCellType(type: DayType): CellType {
    switch (type) {
      case DayType.Free:
        return CellType.FreeDay;
      case DayType.Our:
        return CellType.Planning;
      default:
        return CellType.WeekDay;
    }
  }

  private setDefaultCellValue(rRule: RRuleModel, cell: WeekDayCell): void {
    cell.type = this.getCellType(rRule, cell.date);
    cell.intersectionOrder = null;
    cell.showOrder = false;
    cell.id = null;
    cell.eventDate = null;
  }

  public getCellType(rRule: RRuleModel, date: Date): CellType {
    if (rRule) {
      if (rRule.excludeHolidays && this.holidayService.isHoliday(date)) {
        return CellType.Weekend;
      }
      if (rRule.excludeSundays && String(date.getDay()) === WeekDay.Sunday) {
        return CellType.Weekend;
      }
      if (rRule.excludeSaturdays && String(date.getDay()) === WeekDay.Saturday) {
        return CellType.Weekend;
      }
    }

    return CellType.WeekDay;
  }

  private showOrder(date: Date, selectedDay: string | undefined, transferDate: string | undefined): boolean {
    return (
      (selectedDay ? isSameDay(setTimeZone(selectedDay), setTimeZone(date)) : false) ||
      (transferDate ? isSameDay(setTimeZone(transferDate), setTimeZone(date)) : false)
    );
  }

  private addRecordInIndexMap(date: Date | string, weekDay: string, index: number): void {
    this.indexMap[startOfDayString(date)] = [weekDay, index];
  }

  private getCellByDate(date: string): [weekDay: string, index: number] {
    return this.indexMap[startOfDayString(date)];
  }

  private clearIndexMap(): void {
    this.indexMap = {};
  }

  public initWeekDayMapForYear(rRule: RRuleModel): void {
    const date = new Date(Number(this.selectedYear$.value), 0, 1);
    this.initWeekDayMap(rRule, date);
  }

  public initWeekDayMap(rRule: RRuleModel, date: Date): void {
    this.clearIndexMap();
    const result = {} as WeekDayMap;
    const yearStart = addDays(startOfWeek(startOfYear(date)), 1);
    const yearEnd = endOfWeek(endOfYear(date));
    const daysInYear = eachDayOfInterval({ start: yearStart, end: yearEnd });

    daysInYear.map((d) => {
      const weekDay = String(d.getDay());
      if (result[weekDay]) {
        result[weekDay] = [...result[weekDay], this.createMapCell(rRule, d, date)];
      } else {
        result[weekDay] = [this.createMapCell(rRule, d, date)];
      }
    });
    this.weekDayMap$.next(result);
  }

  private createMapCell(rRule: RRuleModel, d: Date, selectedYear: Date): WeekDayCell {
    return {
      date: addHours(d, 12),
      isSameYear: isSameYear(d, selectedYear),
      isToday: isToday(d),
      type: this.getCellType(rRule, d),
      eventDate: null,
      id: null,
      intersectionOrder: null,
      showOrder: false
    };
  }
}
