<com-form-block class="px-2">
  <h2 class="text-xl leading-8">Несистемные докладчики</h2>
  <com-ekp-select
    [value]="value"
    [hasSearch]="true"
    [shortcuts]="SHORT_CUT"
    [multi]="true"
    [hasPopover]="true"
    placeholder="Составьте список пользователей и должностей"
    (valueChanged)="onSelect($event)"
    (searchChanged)="search$.next($event)"
    (shortcutChanged)="onTabChange($event)"
  >
    <ng-container *ngIf="loading$ | async; else list">
      <div class="flex w-full justify-center">Загрузка..</div>
    </ng-container>

    <ng-template #list>
      <ng-container *ngFor="let member of userOptions$ | async">
        <ekp-select-item hasChecks="true" shortcut="users" [attr.value]="member.id">
          <div class="flex flex-col">
            {{ member.name }}
            <span
              *ngIf="member.isActive !== undefined && !member.isActive"
              class="text-red-500 font-semibold text-[12px]"
            >
              Заблокирован
            </span>
          </div>
        </ekp-select-item>
      </ng-container>

      <ng-container *ngFor="let position of positionOptions$ | async">
        <ekp-select-item hasChecks="true" shortcut="positions" [attr.value]="position.id">
          <div class="flex flex-col">
            {{ position.name }}
          </div>
        </ekp-select-item>
      </ng-container>
    </ng-template>
  </com-ekp-select>
</com-form-block>
