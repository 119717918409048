export type ShortCutValue = { shortcut: string; value: string; name?: string };

export type ShortCutSelectValue = {
  id: string;
  heading: string;
  shortcut?: string;
  subtitle?: string;
  avatar?: string;
};

export enum ShortCut {
  Users = 'users',
  Positions = 'positions'
}

export const SHORT_CUT: { value: ShortCut; label: string }[] = [
  { value: ShortCut.Users, label: 'Пользователи' },
  { value: ShortCut.Positions, label: 'Должности' }
];
