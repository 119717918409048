import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { addYears, endOfYear, isSameDay, startOfYear } from 'date-fns';
import { setTimeZone, toISO } from '@common/dialogs/intersection-dialog/helpers/date.helpers';

type ResponseHoliday = {
  body: Holiday[];
  errorCode: string;
  isSuccess: boolean;
};

export type Holiday = {
  id: string;
  holidayDate: string;
  title: string;
};

@Injectable({
  providedIn: 'root'
})
export class HolidayService {
  public holidays$ = new BehaviorSubject<Holiday[]>([]);

  constructor(private readonly http: HttpClient) {}

  public isHoliday(date: Date): boolean {
    const { value } = this.holidays$;
    return value.some((holiday) => isSameDay(setTimeZone(holiday.holidayDate), date));
  }

  public initHolidays(): void {
    const startYear = toISO(startOfYear(new Date()));
    const endNextYear = toISO(addYears(endOfYear(new Date()), 1));
    this.getHolidays(startYear, endNextYear, 'Россия')
      .pipe(
        tap(({ body }) => {
          this.holidays$.next(body);
        })
      )
      .subscribe();
  }

  private getHolidays(startDate: string, endDate: string, country: string): Observable<ResponseHoliday> {
    const params = new HttpParams()
      .set('startDate', startDate)
      .set('endDate', endDate)
      .set('country', country);
    return this.http.get<ResponseHoliday>('v1/holiday/list', {
      params
    });
  }
}
