import { AppRolesEnum, RoleTypesEnum } from '@common/enums';

export const CHAIRMAN_ROLE_ORDER = 0;

export const ADMIN_APP_ROLES: string[] = [AppRolesEnum.ADMIN, AppRolesEnum.BUSINESS_ADMIN];

export const ROLE_TYPES = [
  { id: RoleTypesEnum.MANDATORY, name: 'Обязательная' },
  { id: RoleTypesEnum.OPTIONAL, name: 'Дополнительная' },
  { id: RoleTypesEnum.DIVISIONAL, name: 'На уровне дивизиона' }
];

export const NON_SYSTEM_SPEAKER_ROLE_ID = 'aec83a65-d9d4-4140-a2f8-5fe85773b714';
