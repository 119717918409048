import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import {
  ShortCut,
  ShortCutSelectValue,
  ShortCutValue
} from '@common/shared/components/form-groups/no-system-members/no-system-members.types';

@Component({
  selector: 'com-ekp-select',
  templateUrl: 'ekp-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EkpSelectComponent implements OnDestroy, AfterViewInit {
  @Input() value: ShortCutSelectValue[];
  @Input() clearable: boolean;
  @Input() hasSearch = false;
  @Input() clientSide = false;
  @Input() size = 'size';
  @Input() placeholder = 'Выберите значение';
  @Input() class: string;
  @Input() multi = false;
  @Input() hasPopover = false;
  @Input() shortcuts: { value: string; label: string }[] = [];

  @Output() valueChanged = new EventEmitter<string | ShortCutValue[]>();
  @Output() searchChanged = new EventEmitter<string>();
  @Output() shortcutChanged = new EventEmitter<string | ShortCut>();
  @Output() selectOpen = new EventEmitter<boolean>();

  @ViewChild('select') select: ElementRef<HTMLEkpCheckboxElement>;

  public JSON = JSON;

  ngAfterViewInit(): void {
    this.select?.nativeElement.addEventListener('valueChange', this.valueChange.bind(this));
    this.select?.nativeElement.addEventListener('searchChange', this.searchChange.bind(this));
    this.select?.nativeElement.addEventListener('openChange', this.openChange.bind(this));
    this.select?.nativeElement.addEventListener('shortcutChange', this.shortcutChange.bind(this));
  }

  ngOnDestroy(): void {
    this.select?.nativeElement.removeEventListener('valueChange', this.valueChange.bind(this));
    this.select?.nativeElement.removeEventListener('searchChange', this.searchChange.bind(this));
    this.select?.nativeElement.removeEventListener('openChange', this.openChange.bind(this));
    this.select?.nativeElement.removeEventListener('shortcutChange', this.shortcutChange.bind(this));
  }

  private valueChange(e: CustomEvent<string>): void {
    this.valueChanged.emit(e.detail);
  }

  private searchChange(e: CustomEvent<string>): void {
    this.searchChanged.emit(e.detail);
  }

  private openChange(e: CustomEvent<boolean>): void {
    if (e.detail === false) {
      this.selectOpen.emit(e.detail);
    }
  }

  private shortcutChange(e: CustomEvent<string>): void {
    this.shortcutChanged.emit(e.detail);
  }
}
