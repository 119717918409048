import { IOption } from './common';
import { IRole } from './role.types';

export interface IEmployeeOption extends IOption {
  position?: IOption;
  department?: IOption;
  isSystem?: boolean;
}

export interface IEmployeeWithInfo extends IEmployee {
  position: string;
  division: string;
  organization?: string;
}

export interface IEmployeeShort {
  id: string;
  fullName: string;
  isActive: boolean;
  position?: string;
  division?: string;
  department?: string;
  organization?: string;
  isSystem?: boolean;
}

export interface IEmployee {
  id: string;
  fullName: string;
  shortName: string;
  position: string;
  division?: string;
  department?: string;
  organization?: string;
  role?: IRole;
  roles?: IRole[];
  avatar?: string;
  isActive?: boolean;
  status?: any;
  isSystem?: boolean;
}

export interface IEmployeeFull {
  id: string;
  fullName: string;
  shortName: string;
  name: string;
  lastName: string;
  patronymic: string;
  position: string;
  division: string;
  department: string;
  appRole: IOption;
  avatar: string;
  email: string;
  isActive: boolean;
  isMailNotificationOn: boolean;
}
