<div class="w-screen h-screen pl-6 py-6 relative flex justify-center overflow-y-scroll com-perfect-scrollbar">
  <div class="flex flex-col">
    <div class="absolute top-4 right-6">
      <button mat-dialog-close="">
        <mat-icon class="text-secondary" svgIcon="heroicons_outline:x"></mat-icon>
      </button>
    </div>
    <div class="flex flex-col gap-4 w-full font-inter max-w-[1318px]">
      <div class="text-2xl font-semibold tracking-tight text-center">Проверка пересечений</div>
      <div class="flex flex-col min-h-[calc(100vh-100px)] justify-between gap-2 mr-6">
        <com-planning-diagram
          class="py-12"
          [weekDayMap]="diagramService.weekDayMap$ | async"
          [disabled]="loading$ | async"
          [legend]="Legend"
          [selectedYear]="diagramService.selectedYear$ | async"
          [years]="diagramService.availableYears$ | async"
          (yearChange)="diagramYearChange($event)"
          [ngClass]="{
            'opacity-50': loading$ | async
          }"
          (cellClick)="onCellClick($event)"
        ></com-planning-diagram>

        <div
          *ngIf="error$ | async; else planning"
          class="text-[16px] text-[#EE5B5B] w-full flex gap-2 justify-center h-full"
        >
          <mat-icon svgIcon="heroicons_solid:exclamation" class="text-[#EE5B5B]"></mat-icon>
          Ошибка при запросе на сервер
        </div>

        <ng-template #planning>
          <com-planning-intersection
            *ngIf="(events$ | async | hasIntersection) || (loading$ | async); else noIntersectionContent"
            [events]="events$ | async"
            [dayInfo]="dayInfo$ | async"
            [transferInfo]="transferMap[(dayInfo$ | async)?.id]"
            [dayLoading]="dayLoading$ | async"
            [eventsLoading]="loading$ | async"
            [employees]="props.employees"
            [planningStart]="planningStart"
            [planningEnd]="planningEnd"
            [currentTab]="currentTab$ | async"
            [duration]="props.rRule.duration"
            [draft]="props.isDraft"
            [freeSlots]="freeSlots"
            (tabChange)="onTabChange($event)"
            [tabs]="tabs"
            (tableSelect)="onTableSelect($event)"
            (eventTransfer)="onEventTransfer($event)"
            (cancel)="dialogRef.close()"
            (save)="onSave(events$.value)"
            (saveAsDraft)="onSaveAsDraft()"
          ></com-planning-intersection>

          <ng-template #noIntersectionContent>
            <ng-container *ngIf="(events$ | async).length === 0; else success">
              <div class="w-full flex justify-center items-center h-[120px] mb-3 bg-gray-50 rounded-md">
                <div class="flex gap-2 justify-center items-center">
                  <mat-icon
                    class="text-red-500 icon-size-8 w-40"
                    svgIcon="heroicons_outline:exclamation"
                  ></mat-icon>
                  <span class="text-red-500">
                    Планирование событий доступно только на текущий и предстоящий год
                  </span>
                </div>
              </div>
            </ng-container>

            <ng-template #success>
              <div class="w-full flex justify-center items-center h-[120px] mb-3 bg-gray-50 rounded-md">
                <div class="flex gap-2 justify-center items-center">
                  <mat-icon
                    class="text-green-500 icon-size-8 w-40"
                    svgIcon="heroicons_outline:shield-check"
                  ></mat-icon>
                  <span class="text-green-500">
                    Пересечений не найдено, комитет доступен для сохранения
                  </span>
                </div>
              </div>
            </ng-template>

            <footer class="flex gap-2 justify-end w-full">
              <com-button (buttonClick)="dialogRef.close()" variant="outline"
                ><span class="text-[14px]">Отмена</span></com-button
              >
              <com-button [disabled]="(events$ | async).length === 0" (buttonClick)="onSave(events$.value)"
                ><span class="text-[14px]">Сохранить</span></com-button
              >
            </footer>
          </ng-template>
        </ng-template>
      </div>
    </div>
  </div>
</div>
