import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { LegendOption, WEEK_IN_YEAR } from '@common/dialogs/planning-dialog/modules/planning-diagram/const';
import { WeekDayCell, WeekDayMap } from '@common/dialogs/planning-dialog/modules/planning-diagram/types';

@Component({
  selector: 'com-planning-diagram',
  templateUrl: 'planning-diagram.component.html',
  styleUrls: ['planning-diagram.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanningDiagramComponent {
  @Input() weekDayMap: WeekDayMap | null = null;
  @Input() disabled: boolean;
  @Input() cellSize = 20;
  @Input() legend: LegendOption[];
  @Input() years: string[];
  @Input() selectedYear: string;

  @Output() cellClick = new EventEmitter<WeekDayCell>();
  @Output() yearChange = new EventEmitter<string>();

  protected weeks = Array.from({ length: WEEK_IN_YEAR - 1 }, (_, i) => i + 1);

  public onDiagramClick(e: MouseEvent): void {
    if (this.disabled) e.stopImmediatePropagation();
  }
}
