<div class="flex justify-between items-center w-full">
  <div class="flex gap-2 items-center cursor-pointer">
    <span class="text-foreground text-[20px] font-[700]"> Годовой график </span>
  </div>
  <com-tab-buttons
    *ngIf="years.length"
    [currentTab]="selectedYear"
    [options]="years"
    (selectTab)="yearChange.emit($event)"
  ></com-tab-buttons>
</div>
