import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { compareByField } from '@common/utils/util';
import { IRole, IRoleAccess, IRoleData } from '@common/types';
import { NON_SYSTEM_SPEAKER_ROLE_ID } from '@common/constants';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  private readonly roles = new BehaviorSubject<IRole[]>([]);

  constructor(private readonly _http: HttpClient) {
    this.loadRoles();
  }

  get roles$(): Observable<IRole[]> {
    return this.roles.asObservable();
  }

  get withoutSystemRoles$(): Observable<IRole[]> {
    return this.roles
      .asObservable()
      .pipe(map((roles) => roles.filter((r) => r.id !== NON_SYSTEM_SPEAKER_ROLE_ID)));
  }

  loadRoles(): void {
    this._http.get<IRole[]>('api/Role').subscribe((roles) => {
      roles.sort(compareByField('order'));
      this.roles.next(roles);
    });
  }

  createRole(role: IRole): Observable<IRole> {
    return this._http.post<IRole>('api/Role', role);
  }

  retrieveRoleAccesses(): Observable<IRoleAccess[]> {
    return this._http.get<IRoleAccess[]>('api/Role/GetAccess');
  }

  retrieveAllRoles(name: string, pageSize: number, page: number): Observable<IRoleData> {
    return this._http
      .get<IRoleData>('api/Role/GetAllRole', {
        params: { ...(name ? { name } : {}), pageSize, page }
      })
      .pipe(
        map((res) => ({
          ...res,
          data: res.data
            .filter((role) => role.id !== NON_SYSTEM_SPEAKER_ROLE_ID)
            .sort(compareByField('order'))
            .map((role) => ({
              ...role,
              accesses: role.accesses.sort((a, b) => a - b)
            }))
        }))
      );
  }

  updateRole(role: IRole): Observable<IRole> {
    return this._http.put<IRole>('api/Role', role);
  }

  deleteRole(id: string): Observable<IRole> {
    return this._http.delete<IRole>('api/Role', { params: { id } });
  }
}
