import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DiagramTab } from '@common/dialogs/planning-dialog/modules/planning-diagram/const';
import { Option } from '@common/dialogs/planning-dialog/types';
@Component({
  selector: 'com-planning-diagram-actions',
  templateUrl: 'planning-diagram-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanningDiagramActionsComponent {
  @Input() selectedYear: string;
  @Input() set setYears(years: string[]) {
    this.years = years.map((y) => ({
      value: y,
      name: y
    }));
  }
  @Output() tabChange = new EventEmitter<DiagramTab>();
  @Output() yearChange = new EventEmitter<string>();

  public years: Option<string>[];
}
