<div class="relative flex flex-col w-full h-full max-h-[90vh]">
  <div class="absolute top-0 right-0">
    <button mat-dialog-close="">
      <mat-icon class="text-secondary" svgIcon="heroicons_outline:x"></mat-icon>
    </button>
  </div>

  <div class="text-2xl font-semibold tracking-tight text-center">{{ props.title }}</div>

  <div class="flex flex-col gap-2 grow overflow-y-scroll com-perfect-scrollbar">
    <com-agenda
      [value]="props.agendaItems"
      [agendaOptions]="props.agendaOptions"
      [members]="props.members"
      [speakers]="props.speakers"
      [compromiseStart]="props.compromiseStart"
      [isSystemAgenda]="false"
      [noSystemAgendaId]="props.editedAgendaId"
      [eventStart]="eventStart"
      [eventEnd]="eventEnd"
      [eventId]="props.eventId"
      (isNoSystemAgendaValid)="checkAgendaIsValid = $event"
      (valueChange)="getAgendaValue($event)"
      (hasIntersection)="hasIntersection$.next($event)"
      (loadIntersection)="loadIntersection$.next($event)"
    ></com-agenda>
  </div>

  <div class="min-w-full min-h-12 mt-4"></div>
  <footer
    class="absolute bottom-0 left-0 mt-4 flex items-end h-12 justify-end gap-4 min-w-full bg-white z-99999"
  >
    <button mat-stroked-button mat-dialog-close="">Отмена</button>
    <button
      class="min-w-[130px]"
      [disabled]="
        (loading$ | async) || (invalid$ | async) || (hasIntersection$ | async) || (loadIntersection$ | async)
      "
      (click)="save()"
      mat-flat-button
      color="primary"
    >
      <div *ngIf="loading$ | async; else getIntersection" class="flex gap-2 items-center">
        <mat-spinner [diameter]="16"></mat-spinner> <span class="text-sm">Идет запрос..</span>
      </div>
      <ng-template #getIntersection>
        <div *ngIf="loadIntersection$ | async; else saveText" class="flex gap-2 items-center">
          <mat-spinner [diameter]="16"></mat-spinner> <span class="text-sm">Проверяем..</span>
        </div>
        <ng-template #saveText> Сохранить </ng-template>
      </ng-template>
    </button>
  </footer>
</div>
