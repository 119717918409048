import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IChangeStatusParams } from '@common/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommitteeEventMemberService {
  private apiUrl = 'api/CommitteeEventMember';
  constructor(private readonly _http: HttpClient) {}

  public joinJitsi(committeeEventId: string): Observable<void> {
    return this._http.get<void>(`${this.apiUrl}/joinJitsi/${committeeEventId}`);
  }

  public leaveJitsi(committeeEventId: string): Observable<void> {
    return this._http.get<void>(`${this.apiUrl}/leaveJitsi/${committeeEventId}`);
  }

  /**
   * Update event member pre resolution status
   *
   * @param eventId
   * @param options
   * @returns
   */
  public updateMemberPreResolutionStatus(eventId: string, options: IChangeStatusParams): Observable<void> {
    const params = new HttpParams().set('status', options.status);

    return this._http.patch<void>(
      `api/calendar/${eventId}/members/${options.employeeId}/reasonPreResolutionStatus`,
      {},
      { params }
    );
  }

  public getNonSystemMembers(eventId: string): Observable<any> {
    return this._http.get<void>(`api/CommitteeEventMember/GetEmployeesForNonSystemAgendaItem/${eventId}`);
  }
}
