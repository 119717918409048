<mat-form-field class="w-full fuse-mat-dense" appearance="fill">
  <mat-select
    [formControl]="control"
    [placeholder]="placeholder"
    (selectionChange)="select.next($event)"
    (openedChange)="triggerEvent($event)"
  >
    <mat-option *ngIf="editable" disabled class="p-0 input-option">
      <div *ngIf="isCreate" class="flex w-full">
        <input matInput placeholder="Добавить значение" #input />
        <button mat-flat-button matSuffix color="primary" (click)="addOption(input.value)">Ок</button>
      </div>
      <button
        mat-raised-button
        class="w-full"
        *ngIf="!isCreate"
        color="primary"
        (click)="isCreate = !isCreate"
      >
        <mat-icon class="icon-size-4 mt-0.5 ml-0.5" svgIcon="heroicons_outline:plus"></mat-icon>
        Создать
      </button>
    </mat-option>
    <mat-option *ngIf="empty"> -- выберите вариант -- </mat-option>
    <ng-container *ngFor="let option of options">
      <ng-container [ngSwitch]="!!option.label">
        <ng-container *ngSwitchCase="true">
          <mat-optgroup *ngIf="option.options.length" [label]="option.label">
            <ng-container *ngFor="let option of option.options">
              <mat-option [value]="option.id" class="block whitespace-normal leading-5 h-auto py-2.5">
                <div [innerHtml]="option.name"></div>
                <span *ngIf="option.hint" class="text-sm text-gray-500">
                  {{ option.hint }}
                </span>
              </mat-option>
            </ng-container>
          </mat-optgroup>
        </ng-container>
        <ng-container *ngSwitchCase="false">
          <mat-option
            *ngIf="!hiddenOptions.includes(option.id) || control.value === option.id"
            [value]="option.id"
            class="whitespace-normal leading-5 h-auto py-2.5"
          >
            <div [innerHtml]="option.name"></div>
            <ng-container *ngIf="showPosition && control.value !== option.id">
              <span
                *ngIf="option.isActive !== undefined && !option.isActive"
                class="text-red-500 font-semibold text-[12px]"
              >
                Заблокирован
              </span>
              <span *ngIf="option.position && option.isActive" class="truncate text-sm text-gray-500">
                {{ option.position.name }}
              </span>
              <span *ngIf="option.department && option.isActive" class="truncate text-sm text-gray-500">
                {{ option.department.name }}
              </span>
            </ng-container>
          </mat-option>
        </ng-container>
      </ng-container>
    </ng-container>
  </mat-select>
  <mat-icon
    *ngIf="clearable && control?.value && !control.disabled"
    matSuffix
    matTooltip="Удалить"
    (click)="clear($event)"
    class="icon-size-6 cursor-pointer ml-1"
    svgIcon="heroicons_outline:x-circle"
  ></mat-icon>
</mat-form-field>
