import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { UnsubscribeService } from '@common/services';
import { BehaviorSubject, takeUntil, tap } from 'rxjs';
import { IntersectionCommitteeService } from '@common/dialogs/planning-dialog/services/intersection-committee.service';
import { EventInterval, UserIntersectionEvent } from '@common/dialogs/planning-dialog/types';
import { IntersectionInfo } from '@common/shared/components/form-groups/user-check/user-check.types';

@Component({
  selector: 'com-user-check',
  templateUrl: 'user-check.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UnsubscribeService]
})
export class UserCheckComponent implements OnChanges {
  @Input() userId: string;
  @Input() eventId: string;
  @Input() eventStart: string;
  @Input() eventEnd: string;
  @Input() index: number;

  @Output() hasIntersection = new EventEmitter<IntersectionInfo>();
  @Output() loading = new EventEmitter<boolean>();

  public hasIntersection$ = new BehaviorSubject<boolean>(false);
  public events: EventInterval[] = [];

  constructor(
    private unsubscribeService: UnsubscribeService,
    private intersectionCommitteeService: IntersectionCommitteeService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userId'] && this.userId) {
      this.checkUserAccess();
    }
  }

  private checkUserAccess(): void {
    if (this.userId && this.eventId) {
      this.loading.emit(true);
      this.intersectionCommitteeService
        .checkUser(this.eventId, this.userId)
        .pipe(
          tap(({ hasIntersection, intersectionEvents }) => {
            this.initEvents(intersectionEvents);
            this.initIntersectionState(hasIntersection);
            this.loading.emit(false);
          }),
          takeUntil(this.unsubscribeService)
        )
        .subscribe();
    }
  }

  private initIntersectionState(hasIntersection: boolean) {
    this.hasIntersection$.next(hasIntersection);
    this.hasIntersection.emit({
      index: this.index,
      hasIntersection
    });
  }

  private initEvents(intersectionEvents: UserIntersectionEvent[]) {
    this.events = intersectionEvents.map((e) => ({
      ...e,
      roleId: null,
      roleName: null
    }));
  }
}
