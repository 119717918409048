<ekp-select
  #select
  [attr.has-search]="hasSearch"
  [attr.placeholder]="placeholder"
  [attr.value]="JSON.stringify(value)"
  [attr.client-side]="clientSide"
  [attr.size]="size"
  class="min-w-[300px] max-w-[300px]"
  [attr.clearable]="clearable"
  [attr.shortcuts]="JSON.stringify(shortcuts)"
  [attr.class]="class"
  [attr.has-popover]="hasPopover"
  [attr.multi]="multi"
>
  <ng-content></ng-content>
</ekp-select>
